@media screen and (min-width: 900px){
    .outer_div{
        position: fixed;
        width: 100%;
        display: flex;
        background-color: rgb(0, 0, 0);
        justify-content: space-around;
        align-items: center;
        z-index: 99;
    }
    .outer_div p{
        font-size: 30px;
        color: white;

    }

    .outer_div p span{
        color: #4caf50;
        font-weight: 400;
    }
    .ul_list{
        height: 8rem;
        
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        width: 60vw;
        
    }


    .ul_list1{
        height: 8rem;
        
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 30px;
        width: 60vw;
        
    }
    .ul_list :hover{
        color: #4caf50;
    }

    .ul_list li{
        
        font-size: 14px;
        font-weight: 400;
        color: white;
        padding: 5px 2vw;
        border-radius: 10px;
        
    }
    .ul_list1 li{
       
        font-size: 14px;
        font-weight: 400;
        color: white;
        padding: 5px 0.8vw;
        border-radius: 10px;

    }

    .ul_list1 :hover{
        color: #4caf50;
    }


    .btn{
        color: rgb(12, 12, 12);
        font-size: 14px;
        background-color: #70a4c9;
        padding: 5px 15px;
        border-radius: 8px;
        font-weight: 500;

    }
    
    .icon{
        font-size: medium;
        color: white;
        display: none;
    
       }
}

    





   @media screen and (max-width: 900px) and (min-width: 520px){
    .icon{
        font-size: medium;
        color: white;
        
    
       }

       .outer_div{
        position: fixed;
        width: 100%;
        display: flex;
        background-color: rgb(0, 0, 0);
        justify-content: space-around;
        align-items: center;
        z-index: 99;
    }
    .outer_div p{
        font-size: 30px;
        color: white;

    }

    .outer_div p span{
        color: #4caf50;
        font-weight: 400;
    }
    .ul_list{
        height: 8rem;
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        background-color: black;
        height: 100vh;
        top: 4rem;
        right:0px;
        width: 50vw;
        padding-top: 30px;
    }

    .ul_list1{
        display: none;
    }
    
    .ul_list :hover{
        color: #4caf50;
    }

    .ul_list li{
        margin: 5px 5px;
        font-size: 16px;
        font-weight: 400;
        color: white;
        padding: 15px 30px;
        border-radius: 10px;
        
    }
    .btn{
        color: rgb(12, 12, 12);
        font-size: 15px;
        background-color: #70a4c9;
        padding: 5px 15px;
        border-radius: 8px;
        font-weight: 500;
    }
   }



@media screen and (max-width:520px){
    .icon{
        font-size: medium;
        color: white;
        
    
       }

       .outer_div{
        position: fixed;
        width: 100%;
        display: flex;
        background-color: rgb(0, 0, 0);
        justify-content: space-around;
        align-items: center;
        z-index: 99;
    }
    .outer_div p{
        font-size: 30px;
        color: white;

    }

    .outer_div p span{
        color: #4caf50;
        font-weight: 400;
    }
    .ul_list{
        height: 8rem;
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        
        flex-direction: column;
        background-color: black;
        height: 100vh;
        top: 4.5rem;
        right:0px;
        width: 65vw;
        padding-top: 30px;
    }
    .ul_list1{
        display: none;
    }
    .ul_list :hover{
        color: #4caf50;
    }

    .ul_list li{
        margin: 5px 5px;
        font-size: 16px;
        font-weight: 400;
        color: white;
        padding: 15px 30px;
        border-radius: 10px;

    }
    .btn{
        color: rgb(12, 12, 12);
        font-size: 15px;
        background-color: #70a4c9;
        padding: 5px 15px;
        border-radius: 8px;
        font-weight: 500;
    }
   }





