@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* reset default css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Montserrat', sans-serif;
}

/* css for all pages */

.container-hero {
  max-width: 111.6rem;
  margin: 0 auto;
  
}

.container {
  max-width: 124rem;
  margin: 0 auto;
}

.page-padding {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.nav-btn-hover:hover {
  transform: rotate(360deg);
  transition: all 1s;
}

.hero-section {
  background-image: url('../src/images/hero/hero-img\(4\).jpg');
  width: 100%;
  height: 110vh;
  object-fit: cover;
  background-size: cover;
  background-position: center center;
}

.hero-text {
  top: 55%;
  right: 12px;
  transform: translate(-50%, -50%);
}

.title-white {
  filter: brightness(0) invert(1);
}

.hero-cta::after {
  content: '';
  width: 20rem;
  height: 5.5rem;
  border: 1px solid rgba(255, 255, 255, 0.362);
  position: absolute;
  top: -12px;
  left: 12px;
  transition: all 0.4s;
}

.hero-cta:hover:after {
  content: '';
  width: 20rem;
  height: 5.5rem;
  border: 1px solid rgba(255, 255, 255);
  position: absolute;
  top: -0px;
  left: 0px;
  transition: all 0.4s;
}

.about-section {
    background-image: url('../src/images/who-we-are/background.jpg');
    object-fit: cover;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
  
  .card-item-div {
    transition: all 0.2s;
  }
  
 
  
  
  .about-cta {
    padding: 1.82rem 3.6rem;
    transition: all 0.3s;
    width: 21rem;
  }
  
  .about-cta::after {
    content: '';
    width: 20rem;
    height: 5.5rem;
    border: 1px solid rgba(63, 63, 63, 0.362);
    position: absolute;
    top: -12px;
    left: 12px;
    transition: all 0.4s;
  }
  
  .about-cta:hover:after {
    content: '';
    width: 20rem;
    height: 5.5rem;
    border: 1px solid #FF0336;
    position: absolute;
    top: -0px;
    left: 0px;
    transition: all 0.4s;
  }
  
  .abt-us {
    width: 100%;
    height: auto;
    top: 60%;
    right: 46%;
    transform: translate(50%, -50%);
    position: absolute;
    z-index: 5;
  }
  
 
  
  .girl-text {
    width: 17%;
    height: auto;
    top: 67.7%;
    right: 84%;
    transform: translate(50%, -50%);
    position: absolute;
  }
  
 


  /* grid  ------------------------------------- */

/* after pseudo */
.item-0-div::after,
.item-1-div::after,
.item-2-div::after,
.item-3-div::after,
.item-4-div::after,
.item-5-div::after {
  content: '';
  height: 69px;
  width: 140px;
  background-color: rgba(255, 255, 255, .3);
  position: absolute;
  left: -49px;
  bottom: 50%;
  border-radius: 0 50px 50px 0;
  transform: rotate(30deg);
  transition: all .4s;
  z-index: 5;
}

.item-0-div:hover:after,
.item-1-div:hover:after,
.item-2-div:hover:after,
.item-3-div:hover:after,
.item-4-div:hover:after,
.item-5-div:hover:after {
  background-color: rgb(255, 255, 255);

}

/* before pseudo */
.item-0-div::before,
.item-1-div::before,
.item-2-div::before,
.item-3-div::before,
.item-4-div::before,
.item-5-div::before {
  width: 50%;
  position: absolute;
  transition: all .4s;
  z-index: 6;
  display: block;
  transform: scale(.65);
}



/* grid items */
.item-0,
.item-1,
.item-2,
.item-3,
.item-4,
.item-5 {
  filter: grayscale(20%);
  object-fit: cover;
  background-size: cover;
  background-position: center;
  box-shadow: inset 2px 2px 8px 160px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.item-0:hover,
.item-1:hover,
.item-2:hover,
.item-3:hover,
.item-4:hover,
.item-5:hover {
  filter: grayscale(0%);
  transform: scale(1.3);
  box-shadow: inset 2px 2px 8px 160px rgba(0, 0, 0, 0);
}


.item-0-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 3;
  overflow: hidden;
}

.item-1-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 4;
  overflow: hidden;
}

.item-2-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: 2;
  grid-column-end: 5;
  overflow: hidden;
}

.item-3-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
  overflow: hidden;
}

.item-4-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
  overflow: hidden;
}

.item-5-div {
  height: 30rem;
  width: 100%;
  grid-row-start: 2;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 5;
  overflow: hidden;
}



/* end of grid ^^ */

.featured-cta {
  background-image: url(../src/images/features/cta-bggreen.jpg);
  width: 100%;
  height: 21rem;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* choose us */

.choose-section {
  background-image: url('../src/images/choose-us/choose-bg.jpg');
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

/*.video-div::before {
  content: "";
  height: 461px;
  width: 10px;
  background-color: #4caf50;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%)rotate(174deg);
  transform: translateY(-50%)rotate(174deg);
  right: 10px;
}

.video-div::after {
  content: "";
  height: 42%;
  width: 10px;
  background-color: #4caf50;
  position: absolute;
  bottom: 0;
  left: -30px;
}
*/
.choose-cta {
  transition: all 0.4s;
  margin-top: 4rem;
}

.choose-cta::after {
  border: 2px solid rgb(89, 89, 89, 0.5);
}

.choose-cta:hover:after {
  border: 2px solid #FF0336;
}


/* trainers section */

.trainers-section {
  background-image: url('../src/images/trainers/dot-bg.png');
  object-fit: cover;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: relative;
}

.trainers-section::after {
  content: url('../src/images/trainers/bg-attachment.png');
  position: absolute;
  top: 0;
  right: 0;
}

.box-desc::after {
  content: "";
  width: 45px;
  height: 2px;
  background-color: #4caf50;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all .4s ease-in-out;
}

.box-desc:hover:after {
  content: "";
  width: 100%;
  height: 4px;
}



/* price section */

.pricing-section {
  background-image: url('../src/images/pricing/background.jpg');
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.pricing-cta {
  transition: all 0.4s;
  margin-top: 4rem;
}

.pricing-cta::after {
  border: 2px solid #ff03353b;

}

.pricing-cta:hover:after {
  border: 2px solid #FF0336;
}



/* gallery grid */

.gallery-grid {
  display: grid;

  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  gap: 6px;
  height: 100%;

}

#item-0 {
  height: 90rem;
  width: 100%;
  background-color: #DE89DD;
  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 3;

  background-image: url('../src/images/gallery/img1.png');
  object-fit: cover;
  background-size:cover;
  /* background-position: 0 510px; */
  background-repeat: no-repeat;
}

#item-1 {

  background-color: #F7D9AB;
  grid-row-start: 1;
  grid-column-start: 3;

  grid-row-end: 2;
  grid-column-end: 5;

  background-image: url('../src/images/gallery/img2.png');
  object-fit: cover;
  background-size: cover;
  background-position: 0px -131px;
  background-repeat: no-repeat;
}

#item-2 {

  background-color: #BC775E;
  grid-row-start: 2;
  grid-column-start: 3;

  grid-row-end: 4;
  grid-column-end: 5;

  background-image: url('../src/images/gallery/img3.png');
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}



/* blog section */
.cta-section {
  background-image: url('../src//images/call/bg-callfinal.jpg');
  object-fit: cover;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}







/* cta banner */

.cta-banner-btn {
  padding: 18px 22px;
}








/* MEDIA QUERIES --------------------------------*/

@media (max-width: 1200px) {
  .navlist-nav {
    display: none;
  }

  .fa-bars {
    display: flex;
  }
}

/* scrollbar ------------------------- */

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #A0A0A0 #FFFFFF;
  scroll-behavior: smooth;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 12px;
  width: 12px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #FFFFFF;
}

*::-webkit-scrollbar-thumb {
  border-radius: 80px;
  background-color: #4caf50;
  border: 1.5px solid #fff;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #A0A0A0;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #A0A0A0;
}